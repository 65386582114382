import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)`
  & > article {
    width: 100%;
  }
`

export const Container = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled.div`
  border: 1px solid #ddd;
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & > div:first-child > h3 {
    font-size: 1.25rem;
  }

  & > div:first-child > img {
    width: 80%;
  }

  & > div:first-child > img.smaller {
    width: 65%;
  }

  & > div:last-child > p {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 0.9rem;
    line-height: 20px;
  }

  & > div:last-child > h4 {
    font-size: 1.1rem;
    font-family: Muli, sans-serif;
  }
`
