import React from "react"
import { navigate, graphql } from "gatsby"
import { getCart, setCart, fmtCurrency } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Button from "../components/Button"
import {
  Main,
  MainContainer,
  Container,
  Item,
} from "../components/content/Thermostats/styled"
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons"

const Thermostats = ({ data }) => {
  const handleSelect = (e, item) => {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has("cart")) {
      let cartIndex = parseInt(urlParams.get("cart"), 10)
      let cartExtraIndex = 999
      let cartItems = getCart()
      cartItems.forEach((cartItem, index) => {
        if (index === cartIndex) {
          cartItem.extras.forEach((cartItemExtra, indexExtra) => {
            if (cartItemExtra.type === "thermostat") {
              cartExtraIndex = indexExtra
            }
          })
        }
      })
      const thermostat = {
        type: "thermostat",
        name: `${item.name} Thermostat`,
        price: item.price,
      }
      if (cartExtraIndex !== 999) {
        cartItems[cartIndex].extras[cartExtraIndex] = thermostat
        setCart(cartItems)
      }
      navigate("/cart")
    }
  }

  return (
    <Layout>
      <Helmet
        name="Thermostats"
        description="Change the thermostat that controls your system."
        slug="thermostats"
      />
      <Header />
      <PageHdr title="Thermostats" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>Upgrade the thermostat that is included with your system.</h2>

              <Container>
                {data.one.edges.map(item => {
                  return (
                    <Item key={item.node.thermostatId}>
                      <div>
                        <h3>{item.node.name}</h3>
                        <img
                          src={item.node.img}
                          alt={item.node.name}
                          className={
                            item.node.name === "Nest 3rd Gen" ? "smaller" : ""
                          }
                        />
                      </div>
                      <div>
                        <p>{item.node.description}</p>
                        <h4>{fmtCurrency(item.node.price)}</h4>
                        <Button
                          item={item.node}
                          click={handleSelect}
                          name="Select this Thermostat"
                          icon={faPlusCircle}
                        />
                      </div>
                    </Item>
                  )
                })}
              </Container>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Thermostats

export const query = graphql`
  query Thermostats {
    one: allACthermostats {
      edges {
        node {
          model
          name
          price
          thermostatId
          description
          img
        }
      }
    }
  }
`
